<template>
<v-text-field
    type="date"
    :value="formattedDate"
    @input="update($event)"
    label="label"
  ></v-text-field>
  </template>

<script>
export default {
    props:{
        label:{
            required: true
        },
        date:{
            required: true
        }
    },
    data(){
        return{
            formattedDate: '',
        }
        
    },
    methods:{
        update(val){
            this.formattedDate = this.formatDate(val, 'YYYY-MM-DD')
        }
    },
    created(){
       this.formattedDate = this.formatDate(this.date, 'YYYY-MM-DD')
    }
};
</script>