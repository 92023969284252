<template>
  <base-material-card color="primary">
    <template v-slot:heading>
      <v-row>
        <v-col>
          <div class="display-2 font-weight-light">
            <v-icon class="mr-3">mdi-file-chart</v-icon>
            Education
          </div>
        </v-col>

        <v-spacer></v-spacer>
        <v-col>
          <v-btn class="float-right" color="purple" @click="newDialog = true">
            <v-icon>mdi-plus</v-icon>
            Add New Education</v-btn
          >
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col md="3" v-for="(item, index) in user.educations" :key="item.id">
        <v-card class="mx-auto" max-width="250">
          <a :href="fileUrl + item.certificateFile" target="_blank">
            <v-img
              :src="fileUrl + item.certificateFile"
              height="200"
            ></v-img>
          </a>
          <v-card-text class="text--primary">
            <div>Institute : {{ item.institute }}</div>
            <div>Title : {{ item.title }}</div>
            <div class="body mt-2">
              {{ item.description }}
            </div>

            <v-card-subtitle class="pl-0 pb-0 subtitle-1">
              <div>From Year : {{ formatDate(item.fromDate) }}</div>
              <div>To Year : {{ formatDate(item.toDate) }}</div>
            </v-card-subtitle>
          </v-card-text>

          <v-card-actions>
            <v-btn color="blue" text small @click="editItem(item, index)">
              <v-icon small class="mr-2"> mdi-pencil </v-icon> Edit
            </v-btn>

            <v-btn color="red" text small @click="deleteItem(item.id)">
              <v-icon small> mdi-delete </v-icon>
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="user.educations && user.educations.length == 0">
      <v-col>
        <h2 class="text-center py-5 display-1">
          You have Not Uploaded Any Education Documents
        </h2>
      </v-col>
    </v-row>

    <!-- Edit Report Card Dialog Box -->
    <v-dialog v-model="editDialog" max-width="500px">
      <v-toolbar color="primary" dark
        >Edit Education Card

        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="white"
        ></v-progress-linear>
      </v-toolbar>
      <v-card :disabled="loading">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentEditItem.institute"
                  label="Institute"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentEditItem.title"
                  label="Title"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentEditItem.description"
                  label="Description"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  type="date"
                  v-model="currentEditItem.fromDate"
                  label="From Date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  type="date"
                  v-model="currentEditItem.toDate"
                  label="To Date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-file-input
                  @change="changeFile"
                  label="Upload a file"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editDialog = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveEditParticular">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End of Edit Report Dialog Box -->

    <!-- New Report Card Dialog Box -->
    <v-dialog v-model="newDialog" max-width="500px">
      <v-toolbar color="primary" dark
        >Create Education Card

        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="white"
        ></v-progress-linear>
      </v-toolbar>
      <v-card :disabled="loading">
        <v-card-title> </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="particular.institute"
                  label="Institute"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="particular.title"
                  label="Title"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="particular.description"
                  label="Description"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  type="date"
                  v-model="particular.fromDate"
                  label="From Date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  type="date"
                  v-model="particular.toDate"
                  label="To Date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-file-input
                  @change="changeFile"
                  label="Upload a file"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="uploadFile"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Of Create New Report Card Dialog BOx -->
  </base-material-card>
</template>


<script>
export default {
  data() {
    return {
      fileUrl: process.env.VUE_APP_FILE_URL +'/',
      itemDetails: [],
      user: {},

      loading: false,

      // new report Card
      particular: {},
      newDialog: false,
      //

      editDialog: false,
      currentEditItem: {},
      // edit item

      file: null,
    };
  },
  methods: {
    changeFile(args) {
      this.file = args;
    },

    getFileUrl(file) {
      return URL.createObjectURL(file);
    },

    // Create New Education
    createEducation() {
      this.particular.expertId = this.$route.params.id;
      this.$ExpertProfile
        .uploadEducation(this.particular)
        .then((data) => {
          this.close();
          this.getProfile();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    editEducation() {
      this.currentEditItem.expertId = this.$route.params.id;
      this.$ExpertProfile
        .updateEducation(this.currentEditItem)
        .then((data) => {
          this.close();
          this.getProfile();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    uploadFile(mode) {
      this.loading = true;
      let formData = new FormData();
      formData.append("file", this.file);
      this.$File
        .uploadFile(formData)
        .then((data) => {
          if (mode == "edit") {
            this.currentEditItem.certificateFile = data.data.data;
            this.editEducation();
          } else {
            this.particular.certificateFile = data.data.data;
            this.createEducation();
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    // Delete Item Card
    deleteItem(id) {
      this.$ExpertProfile
        .deleteEducation(this.$route.params.id, id)
        .then((data) => {
          this.getProfile();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Edit Item Card
    saveEditParticular() {
      if (this.file) {
        this.uploadFile("edit");
      } else {
        this.editEducation();
      }
    },

    editItem(item, index) {
      this.editDialog = true;
      this.currentEditItem.index = index;
      Object.assign(this.currentEditItem, item);
    },

    close() {
      this.newDialog = false;
      this.editDialog = false;
      this.file = null;
      this.particular = {};
      this.currentEditItem = {};
    },

    checkFileType(file) {
      return file.split(".").pop();
    },

    getProfile() {
      this.$Expert
        .getExpertById(this.$route.params.id)
        .then((data) => {
          this.user = data.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    this.getProfile();
  },
  created() {},
};
</script>
