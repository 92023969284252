<template>
<v-container>
   <v-card color="basil">
        <v-tabs v-model="tab" background-color="transparent" grow>
          <v-tab>
            Profile
          </v-tab>
           <v-tab>
            Education
          </v-tab>
           <v-tab>
            Experience
          </v-tab>
        </v-tabs>
      </v-card>
      <v-tabs-items v-model="tab">
        <v-tab-item style="background: transparent !important" >
         <Profile/>
        </v-tab-item>
        <v-tab-item>
          <Education/>
        </v-tab-item>

        <v-tab-item>
          <Experience/>
        </v-tab-item>
      </v-tabs-items>
      </v-container>


 
</template>

<script>
import Education from "./components/Education.vue";
import Experience from "./components/Experience.vue";
import Profile from "./components/Profile.vue";

export default {
  data(){
    return{
      tab:0
    }
  },
  components:{
    Education,
    Experience,
    Profile
  }
  
}
</script>